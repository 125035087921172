import React from 'react'

import styles from './MultiPoints.module.scss'

export default ({title, points}) => (
	<section className={styles.multiPoints}>
		<h2 className={styles.title}>{title}</h2>
		<div className={styles.multiPointsInfo}>
			{points.map((point,i) =>
				<div className={styles.infoPoint} key={i}>
					{point.icon}
					<span className={styles.infoCopy}>{point.copy}</span>
				</div>
			)}	
		</div>
	</section>
)