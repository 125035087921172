import React from 'react'

import styles from './HeaderSection.module.scss'
import bookshelf from '../../../../static/images/living-room/bookshelf.jpeg'
import HeaderImage from 'components/HeaderImage'
import Button from 'components/shared/Button'

const ActionButtons = () => (
	<div className={styles.actionButtons}>
		<Button big onClick={()=>document.getElementById('contact-form').scrollIntoView()}>Get Started</Button>
	</div>
)

export default ({image}) => (
	<HeaderImage 
		title="Plants for the office" 
		titleClass={styles.title}
		subSection="Bring the best of nature into your workplace"
		copyClass={styles.copy}
		actionButtons={<ActionButtons/>}
		image={image}/>
)

