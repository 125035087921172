import React, { useState } from "react"
import { StaticQuery, graphql } from 'gatsby'

import { FormInput, FormRow, FormCol, FormContainer } from 'components/shared/Forms'
import HeaderImage from 'components/HeaderImage'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

function ContactForm() {
	const [state, setState] = useState({})
    const [submitted, setSubmitted] = useState(false)

	const handleChange = (e) => {
	    setState({ ...state, [e.target.name]: e.target.value })
	}

    const handleSubmit = e => {
    	 if (window) {
            window.analytics.track("Business Inquiry")
        }
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "business", email })
      })
        .then(() => setSubmitted(true))
        .catch(error => alert(error));

      e.preventDefault();
    }
    return (
    	<div id="contact-form" style={{paddingTop:40}}>
    		<h2 style={{textAlign:'center'}}>Contact Us</h2>
	    	<FormContainer >
	    		{
	    		submitted && <p style={{textAlign:'center'}}>We will be in contact shortly! 🌱</p>
	    		}
		        <form 
		        	
		            name="business"
		            method="post"
		            data-netlify="true"
		            data-netlify-honeypot="bot-field"
		            onSubmit={handleSubmit}>
		            <input type="hidden" name="bot-field" />
		            <FormRow>
		            	<FormCol>
			            	<FormInput label="Company Name">
			            		<input className="input" type="text" name="name" onChange={handleChange}  required />
			            	</FormInput>
		            	</FormCol>
		            </FormRow>
		            <FormRow>
		            	<FormCol>
			            	<FormInput label="Email">	            		
			            		<input className="input" type="email" name="email" onChange={handleChange}  required/>
		            		</FormInput>
		            	</FormCol>
		            	<FormCol>
			            	<FormInput label="Phone Number">	            		
			            		<input className="input" type="text" name="phone" onChange={handleChange} required/>
		            		</FormInput>
		            	</FormCol>
		            </FormRow>
		             <FormRow>
		            	<FormCol>
			            	<FormInput label="Message">	
			            		<textarea rows="5" type="text" name="message" onChange={handleChange} required/>
			            	</FormInput>
			            </FormCol>
			        </FormRow>
		            <FormRow>
		                <input className="button button-primary button-block button-shadow" type="submit" value="Submit"/>
		        	</FormRow>
		        </form>
	        </FormContainer>
        </div>
    )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "office/bright-3.jpeg" }) {
		  childImageSharp {
		    fluid(maxWidth: 800) {
		      ...GatsbyImageSharpFluid_withWebp
		    }
		  }
		}
      }
    `}
    render={data =>
	<HeaderImage 
		reverse
		collection={<ContactForm/>}
		size="large"
		image={data.file.childImageSharp.fluid}/>
	}/>
)








