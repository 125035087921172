import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/Base'
import SEO from 'components/shared/SEO'
import HeaderImage from 'components/HeaderImage'
import { HeaderSection, ContactSection, RecommendedSection, WhySection, PointsSection } from 'components/OfficePage'
import Spacer from 'components/shared/Spacer'

function OfficePage({data}) {
  return (
    <div>
      <HeaderSection image={data.file.childImageSharp.fluid} />
      <Spacer/>
      <WhySection />
      <Spacer />
      <PointsSection />
      <ContactSection />
      <Spacer />
    </div>
  )
}

export default ({ data, location }) => {
  return (
    <>
      <SEO title="Plants for offices"/>
      <OfficePage data={data}/>
    </>
  )
}


export const query = graphql`
  query {
    file(relativePath: { eq: "office/bright.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`