import React from 'react'
import Img from 'gatsby-image'

import styles from './HeaderImage.module.scss'


export default ({size,title,containerClass,titleClass,subSection,copyClass,image,collection,actionButtons,reverse=false}) => (
	<section className={[styles.heroContainer,containerClass?containerClass:'',reverse ? styles.reverse : ''].join(' ')}>
		<Img fluid={image} className={[styles.heroImage,size=="small"?styles.small:'',size=="large"?styles.large:''].join(' ')} />
		<div className={styles.heroBody}>
		{
			collection ? collection :
			<div>
				<h1 className={[styles.heroTitle,titleClass&&titleClass].join(' ')}>{title}</h1>
				<div className={[styles.heroCopy,copyClass&&copyClass].join(' ')}>
					{subSection}
				</div>
				<div className={styles.actionContainer}>
					{actionButtons}
				</div>
			</div>
		}
		</div>
	</section>
)
