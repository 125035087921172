import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import bookshelf from '../../../../static/images/office/bright-2.jpeg'
import HeaderImage from 'components/HeaderImage'
import Button from 'components/shared/Button'


export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "office/bright-2.jpeg" }) {
		  childImageSharp {
		    fluid(maxWidth: 800) {
		      ...GatsbyImageSharpFluid_withWebp
		    }
		  }
		}
      }
    `}
    render={data =>
	<HeaderImage 
		reverse
		title="Why are office plants important?" 
		subSection="Plants have been shown to boost serotonin levels and dissolve volatile airborne chemicals. That, and they look great too."
		size="medium"
		image={data.file.childImageSharp.fluid}/>
	}/>
)